@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300,400,700&display=swap');


/* Custom global styles */
html, body {
  @apply h-full bg-white;
}

ul {
  @apply list-none;
}

a {
  @apply text-blue-500 hover:text-blue-700;
}

/* Underline fade animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    width: 100%;
  }
  to {
    opacity: 0;
    width: 0;
  }
}

/* Active underline effect */
.underline-active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  animation: fadeIn 0.3s ease-in-out;
}

.underline-active.exiting::after {
  animation: fadeOut 0.3s ease-in-out;
}

@keyframes pulseBorder {
  0% {
    border-width: 1px;
  }
  50% {
    border-width: 2px;
  }
  100% {
    border-width: 1px;
  }
}

.default-border {
  border: 1px solid black;
}

.pulse-border {
  animation: pulseBorder 2s infinite ease-in-out;
  border: 1px solid black; /* Ensure consistent black border */
}

