@tailwind base;
@tailwind components;
@tailwind utilities;

/* Adjust the position of the dots */
.slick-dots {
  position: absolute;
  bottom: -28px; /* Adjust as needed to move below the video */
  width: 100%;
  display: flex !important;
  justify-content: center;
}

/* Optional: Customize dot appearance */
.slick-dots li button:before {
  color: #000; /* Dot color */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Active dot color */
}

